<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.stall_allocation_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        disabled
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4" v-if="search.circular_type !== 2">
                    <b-form-group
                      label-for="payment_type"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.payment_type') }}
                      </template>
                      <b-form-select
                        plain
                        id="payment_type"
                        :options="paymentTypeList"
                        v-model="search.payment_type"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card v-if="finalList.length">
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.stall_allocation_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.stall_allocation_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search_param.fair_id) ? getSearchFairName(search_param.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="12" class="text-left">
              {{ $t('fair_report.space_category') }} : <strong>{{ getCategoryName(search_param.stall_category) }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <table v-if="parseInt(search_param.circular_type) === 1" style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th style="width: 25%;">{{ $t('exportCipReport.org_name_address') }}</th>
                        <th style="width: 15%;">{{ $t('fair_report.applicant_name_phone_number') }}</th>
                        <th>{{ $t('tradeFairConfig.stall_type') }}</th>
                        <th>{{ $t('ditfConfig.stall_no') }}</th>
                        <th>{{ $t('fair_report.bidding_price') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="finalList.length">
                      <span hidden>{{ $si = 1 }}</span>
                      <template v-for="(items, index) in finalList">
                        <slot v-if="search.price_type === 2">
                            <tr v-for="(item, index1) in items.assigned_stalls" :key="index1+index">
                              <td class="text-center"> {{$n($si++)}}</td>
                              <td>
                                <strong>{{ currentLocale === 'bn' ? item.booked_stall?.app_main?.comp_name_bn : item.booked_stall?.app_main?.comp_name_en }}</strong>
                                 <br>
                                <span v-if="item.booked_stall?.app_main?.address.country_type === 1">
                                  {{item.booked_stall?.app_main?.address !== undefined ? AddressHelper.getCommonAddress(item.booked_stall?.app_main?.address) + ', ' + getCountry(item.booked_stall?.app_main?.address.country_id) : ''}}
                                </span>
                                <span v-else>
                                  {{ item.booked_stall?.app_main?.address !== undefined ? AddressHelper.getCommonAddress(item.booked_stall?.app_main?.address) + (currentLocale === 'bn' ? item.booked_stall?.app_main?.address.city_bn : item.booked_stall?.app_main?.address.city_en) + ', ' + (currentLocale === 'bn' ? item.booked_stall?.app_main?.address.state_bn : item.booked_stall?.app_main?.address.state_en) + ', ' + getCountry(item.booked_stall?.app_main?.address.country_id) : ''}}
                                </span>
                              </td>
                              <td>
                                <strong>{{ currentLocale === 'bn' ? item.booked_stall?.app_main?.name_bn : item.booked_stall?.app_main?.name_en }}</strong> <br>
                                {{ item.booked_stall?.app_main?.mobile_no | mobileNumber }}
                              </td>
                              <td>{{ getStallType(items.stall_type) }}</td>
                              <td>
                                {{ item.stall_no }}
                              </td>
                              <td class="text-right">
                                {{item.booked_stall?.quoted_price !== undefined ?  $n(item.booked_stall?.quoted_price) : $n(0) }} <br>
                                {{ $t('externalTradeFair.vat_15') + ':' + (item.booked_stall?.quoted_price !== undefined ?  $n(getVat(item.booked_stall?.quoted_price)) : $n(0)) }}<br>
                                {{ $t('externalTradeFair.tax_10') + ':' + (item.booked_stall?.quoted_price !== undefined ?  $n(getTex(item.booked_stall?.quoted_price)) : $n(0)) }}<br>
                                {{ $t('globalTrans.total') + ':' + (item.booked_stall?.quoted_price !== undefined ?  $n(totalAmount(item.booked_stall?.quoted_price)) : $n(0)) }}<br>
                                <span class="badge badge-danger" v-if="parseInt(item.booked_stall.is_paid) === 0">{{$t('globalTrans.unpaid')}}</span>
                                <span class="badge badge-warning" v-else-if="parseInt(item.booked_stall.is_paid) === 1">{{$t('globalTrans.processing_counter')}}</span>
                                <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                              </td>
                            </tr>
                            <tr :key="index">
                              <td colspan="5" class="text-right"><strong>{{ $t('globalTrans.total') }}</strong></td>
                              <td class="text-right">
                                {{ $n(getQuotedPrice(items.assigned_stalls)) }}
                              </td>
                            </tr>
                        </slot>
                        <slot v-if="search.price_type === 1">
                          <!-- <pre :key="index"> {{ items.assigned_stalls[0].booked_stalls }}</pre> -->
                            <tr v-for="(item, index1) in items.assigned_stalls[0].booked_stalls" :key="index1+index">
                             <td class="text-center"> {{$n($si++)}}</td>
                             <td>
                                  <strong> {{ currentLocale === 'bn' ? item?.app_main?.comp_name_bn : item?.app_main?.comp_name_en }} </strong>
                                  <br>
                                  <span v-if="item?.app_main?.address.country_type === 1">
                                    {{item?.app_main?.address !== undefined ? AddressHelper.getCommonAddress(item?.app_main?.address) + ', ' + getCountry(item?.app_main?.address.country_id) : ''}}
                                  </span>
                                  <span v-else>
                                    {{ item?.app_main?.address !== undefined ? AddressHelper.getCommonAddress(item?.app_main?.address) + (currentLocale === 'bn' ? item?.app_main?.address.city_bn : item?.app_main?.address.city_en) + ', ' + (currentLocale === 'bn' ? item?.app_main?.address.state_bn : item?.app_main?.address.state_en) + ', ' + getCountry(item?.app_main?.address.country_id) : ''}}
                                  </span>
                              </td>
                              <td>
                                <strong>{{ currentLocale === 'bn' ? item?.app_main?.name_bn : item?.app_main?.name_en }}</strong> <br>
                                {{ item?.app_main?.mobile_no | mobileNumber }}
                              </td>
                              <td>{{ getStallType(items.stall_type) }}</td>
                              <td>
                                {{ item.booked_stall_no }}
                              </td>
                              <td class="text-right">
                                {{item?.quoted_price !== undefined ?  $n(item?.quoted_price) : $n(0) }}
                                <br>
                                {{ $t('externalTradeFair.vat_15') + ':' + (item?.quoted_price !== undefined ?  $n(getVat(item?.quoted_price)) : $n(0)) }}<br>
                                {{ $t('externalTradeFair.tax_10') + ':' + (item?.quoted_price !== undefined ?  $n(getTex(item?.quoted_price)) : $n(0)) }}<br>
                                {{ $t('globalTrans.total') + ':' + (item?.quoted_price !== undefined ?  $n(totalAmount(item?.quoted_price)) : $n(0)) }}<br>
                                <span class="badge badge-danger" v-if="parseInt(item.is_paid) === 0">{{$t('globalTrans.unpaid')}}</span>
                                <span class="badge badge-warning" v-else-if="parseInt(item.is_paid) === 1">{{$t('globalTrans.processing_counter')}}</span>
                                <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                              </td>
                            </tr>
                            <tr :key="index">
                              <td colspan="5" class="text-right"><strong>{{ $t('globalTrans.total') }}</strong></td>
                              <td class="text-right">
                                {{ $n(getFixedQuatedPriceTotal(items.assigned_stalls[0].booked_stalls)) }}
                              </td>
                            </tr>
                        </slot>
                      </template>
                    </tbody>
                    <tbody v-if="finalList.length">
                      <tr>
                        <td colspan="5" class="text-right"><strong>{{ $t('globalTrans.total') + ' ' + $t('externalTradeFair.vat_15') }}</strong></td>
                        <td v-if="search.price_type === 2" class="text-right">
                          {{ $n(getFixedQuatedPriceVat(finalList)) }}
                        </td>
                        <td v-else class="text-right">
                          {{ $n(getFixedQuatedPriceVatOne(finalList[0].assigned_stalls[0].booked_stalls)) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="finalList.length">
                      <tr>
                        <td colspan="5" class="text-right"><strong>{{ $t('globalTrans.total') + ' ' + $t('externalTradeFair.tax_10') }}</strong></td>
                        <td v-if="search.price_type === 2" class="text-right">
                          {{ $n(getFixedQuatedPriceTax(finalList)) }}
                        </td>
                        <td v-else class="text-right">
                          {{ $n(getFixedQuatedPriceTaxOne(finalList[0].assigned_stalls[0].booked_stalls)) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="finalList.length">
                      <tr>
                        <td colspan="5" class="text-right"><strong>{{ $t('eBizProgram.grand_total') }}</strong></td>
                        <td v-if="search.price_type === 2" class="text-right">
                          {{ $n(getGrandTotalPrice(finalList)) }}
                        </td>
                        <td v-else class="text-right">
                          {{ $n(getFixedQuatedPrice(finalList[0].assigned_stalls[0].booked_stalls)) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="8" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfStallWiseBiddingReport } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        price_type: 0,
        stall_category: 0,
        payment_type: 0
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        payment_type: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
        const stallCategory = this.stallCategoryList.find(item => item.value === newVal)
        this.search.price_type = stallCategory !== undefined ? stallCategory.price_type : 0
      }
    },
    'search.payment_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    paymentTypeList () {
      return [
        { text: this.$i18n.locale === 'bn' ? 'পরিশোধিত' : 'Paid', value: 1 },
        { text: this.$i18n.locale === 'bn' ? 'অপরিশোধিত' : 'Unpaid', value: 2 }
      ]
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getVat (value) {
      return (parseFloat(value) * (15 / 100))
    },
    getTex (value) {
      return (parseFloat(value) * (10 / 100))
    },
    totalAmount (value) {
      let vat = 0
      let tax = 0
      vat = (parseFloat(value) * (15 / 100))
      tax = (parseFloat(value) * (10 / 100))
      return (parseFloat(value) + parseFloat(vat) + parseFloat(tax))
    },
    getQuotedPrice (item) {
      let totalPrice = 0
      item.map((itm) => {
        totalPrice += itm.booked_stall?.quoted_price !== undefined ? parseInt(itm.booked_stall?.quoted_price) : 0
      })
      return totalPrice
    },
    getFixedQuatedPriceVat (items) {
      let grandTotal = 0
      items.map((item) => {
        let totalPrice = 0
        item.assigned_stalls.map((itm) => {
          totalPrice += itm.booked_stall?.quoted_price !== undefined ? parseInt(itm.booked_stall?.quoted_price) : 0
        })
        grandTotal += parseInt(totalPrice)
      })
      let vat = 0
      vat = (parseFloat(grandTotal) * (15 / 100))
      return vat
    },
    getFixedQuatedPriceVatOne (item) {
      let totalPrice = 0
      item.map((itm) => {
        totalPrice += itm?.quoted_price !== undefined ? parseInt(itm?.quoted_price) : 0
      })
      let vat = 0
      vat = (parseFloat(totalPrice) * (15 / 100))
      return vat
    },
    getFixedQuatedPriceTax (items) {
      let grandTotal = 0
      items.map((item) => {
        let totalPrice = 0
        item.assigned_stalls.map((itm) => {
          totalPrice += itm.booked_stall?.quoted_price !== undefined ? parseInt(itm.booked_stall?.quoted_price) : 0
        })
        grandTotal += parseInt(totalPrice)
      })
      let tax = 0
      tax = (parseFloat(grandTotal) * (10 / 100))
      return tax
    },
    getFixedQuatedPriceTaxOne (item) {
      let totalPrice = 0
      item.map((itm) => {
        totalPrice += itm?.quoted_price !== undefined ? parseInt(itm?.quoted_price) : 0
      })
      let vat = 0
      vat = (parseFloat(totalPrice) * (10 / 100))
      return vat
    },
    getFixedQuatedPrice (item) {
      let totalPrice = 0
      item.map((itm) => {
        totalPrice += itm?.quoted_price !== undefined ? parseInt(itm?.quoted_price) : 0
      })
      let tax = 0
      let vat = 0
      tax = (parseFloat(totalPrice) * (15 / 100))
      vat = (parseFloat(totalPrice) * (10 / 100))
      return (parseFloat(totalPrice) + parseFloat(tax) + parseFloat(vat))
    },
    getFixedQuatedPriceTotal (item) {
      let totalPrice = 0
      item.map((itm) => {
        totalPrice += itm?.quoted_price !== undefined ? parseInt(itm?.quoted_price) : 0
      })
      return parseFloat(totalPrice)
    },
    getGrandTotalPrice (items) {
      let grandTotal = 0
      items.map((item) => {
        let totalPrice = 0
        item.assigned_stalls.map((itm) => {
          totalPrice += itm.booked_stall?.quoted_price !== undefined ? parseInt(itm.booked_stall?.quoted_price) : 0
        })
        grandTotal += parseInt(totalPrice)
      })
      let tax = 0
      let vat = 0
      tax = (parseFloat(grandTotal) * (15 / 100))
      vat = (parseFloat(grandTotal) * (10 / 100))
      return (parseFloat(grandTotal) + parseFloat(tax) + parseFloat(vat))
    },
    getCountry (id) {
      const sectorObj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    circularType (fair) {
      const sectorObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(el => el.value === parseInt(fair))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfStallWiseBiddingReport, this.search)
      if (result.success) {
        this.search_param = result.search_data
        this.finalList = result.data
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getCompanyTypeName (id) {
      const companyType = this.$store.state.TradeFairService.commonObj.companyTypeList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getStallType (id) {
      const stallType = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(obj => obj.value === parseInt(id))
      if (typeof stallType !== 'undefined') {
        return this.$i18n.locale === 'en' ? stallType.text_en : stallType.text_bn
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, year: this.search_param.year, circular_type: this.search_param.circular_type, fair_id: this.search_param.fair_id, stall_category: this.search_param.stall_category, payment_type: this.search_param.payment_type, price_type: this.search.price_type })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfStallWiseBiddingReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
